<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">
      {{ type == "create" ? "AGREGAR" : "EDITAR" }} CATEGORIAS
    </h1>
    <b-form ref="formAdd">
      <div class="row p-3 mb-5">
        <!-- nombre -->
        <b-form-group
          id="nombre"
          label="Nombre*"
          label-for="nombre"
          class="col-md-6"
        >
          <b-form-input
            id="nombre"
            v-model.trim="form.nombre"
            type="text"
            placeholder="Nombre de la categoría"
            ref="nombre"
            :state="nombreState"
            required
          ></b-form-input>
        </b-form-group>
        <!-- descripcion -->
        <b-form-group
          id="descripcion*"
          label="Descripción"
          label-for="descripcion"
          class="col-md-6"
        >
          <b-form-input
            id="descripcion"
            v-model.trim="form.descripcion"
            type="text"
            placeholder="De que trata la categoria"
            ref="descripcion"
            :state="descripcionState"
            required
          ></b-form-input>
        </b-form-group>
        <!-- input image1 -->
        <b-form-group
          label="Imagen"
          :class="type == 'edit' && !imagenTocada ? 'col-md-4' : 'col-md-6'"
        >
          <b-form-file
            v-model="imagen"
            ref="imagen"
            :state="imagenState"
            placeholder="Imagen no mayor a 2mb"
            drop-placeholder="Sueltala aqui..."
            accept=".jpg, .png, .jpeg"
            required
          ></b-form-file>
        </b-form-group>
        <div
          class="col-md-2"
          v-if="type == 'edit' && !imagenTocada && form.imagen"
        >
          <img
            :src="`${urlImagesCategorias}${form.imagen}`"
            alt="imagen categoría"
            height="100"
          />
        </div>
        <!-- disponible -->
        <b-form-group label="Disponible" class="col-md-6">
          <b-form-radio-group
            v-model="form.disponible"
            :options="optionsDisponible"
            class="mb-3"
            value-field="item"
            text-field="name"
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <b-button variant="primary" @click="handleSubmit">Aceptar</b-button>
      <b-button
        variant="danger"
        class="ml-3"
        @click="$router.push({ name: 'CategoriasList' })"
        >Cancelar</b-button
      >
    </b-form>
  </div>
</template>

<script>
import CategoriasServices from "@/services/categorias.service";
import EventBus from "@/services/eventBus.js";
export default {
  name: "UserProcessAdmin",
  data() {
    return {
      categoriasServices: null,
      form: {
        nombre: null,
        descripcion: null,
        disponible: 2,
        mostrar_en_categorias_propias: 1,
      },
      imagen: null,
      nombreState: null,
      descripcionState: null,
      imagenState: null,
      imagenTocada: false,
      optionsDisponible: [
        { item: 1, name: "SI" },
        { item: 2, name: "NO" },
      ],
      type: "create",
      urlImagesCategorias: process.env.VUE_APP_API_IMAGES_CATEGORIAS,
    };
  },
  created() {
    this.categoriasServices = new CategoriasServices();
    this.$route.params.id ? (this.type = "edit") : (this.type = "create");
  },
  mounted() {
    if (this.type == "edit") {
      this.recoveryDataRegister();
    }
  },
  methods: {
    handleSubmit() {
      if (!this.validation()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debes completar los campos obligatorios",
        });
        return false;
      }
      if (this.type === "create") {
        this.createRegister();
      } else {
        this.editRegister();
      }
    },
    recoveryDataRegister() {
      this.categoriasServices
        .getById(this.$route.params.id)
        .then((result) => {
          if (result.data.data) {
            this.form = { ...result.data.data };
          } else {
            this.$router.push({ name: "CategoriasList" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createRegister() {
      this.inProcess = true;
      let formData = new FormData();
      formData.append("file", this.imagen);
      formData.append("data", JSON.stringify(this.form));
      this.categoriasServices
        .createCategoria(formData)
        .then((result) => {
          if (result.data.status == 200) {
            this.form = {
              nombre: null,
              descripcion: null,
              disponible: 2,
              mostrar_en_categorias_propias: 1,
            };
            this.imagen = null;
            this.$refs.imagen.reset();
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro creado satisfactoriamente",
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al guardar el registro",
          });
          this.inProcess = false;
        });
    },
    editRegister() {
      this.inProcess = true;
      let formData = new FormData();
      if (this.imagenTocada) {
        formData.append("file", this.imagen);
      }
      formData.append("data", JSON.stringify(this.form));
      this.categoriasServices
        .editCategoria(formData)
        .then((result) => {
          if (result.data.status == 200) {
            this.form = {
              nombre: null,
              descripcion: null,
              disponible: 2,
              mostrar_en_categorias_propias: 1,
            };
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Registro editado satisfactoriamente",
            });
            this.$router.push({ name: "CategoriasList" });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: result.data.errores.join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al editar el registro",
          });
          this.inProcess = false;
        });
    },
    onReset() {
      this.form = {
        nombre: null,
        descripcion: null,
        disponible: 2,
        mostrar_en_categorias_propias: 1,
      };
      this.imagen = null;
      this.$refs.imagen.reset();
      this.$nextTick(() => {
        this.$refs.formAdd.reset();
      });
    },
    validation() {
      /* nombre */
      const validNombre = this.$refs.nombre.checkValidity();
      this.nombreState = validNombre;
      /* descripcion */
      const validDescripcion = this.$refs.descripcion.checkValidity();
      this.descripcionState = validDescripcion;
      /* imagen */
      let validImagen = true;
      if (this.type == "create" || (this.type == "edit" && this.imagenTocada)) {
        validImagen = this.$refs.imagen.isFileValid(this.imagen);
        this.imagenState = validImagen;
      }
      return validNombre && validDescripcion && validImagen;
    },
  },
  watch: {
    $route(to) {
      to.params.id ? (this.type = "edit") : (this.type = "create");
      if (this.type == "edit") {
        this.recoveryDataRegister();
      }
    },
    imagen(newValue) {
      if (newValue) {
        if (newValue.size > 2048000) {
          this.$refs.imagen.reset();
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "warning",
            message: "El tamaño de la imagen no debe ser mayor a 2mb",
          });
          this.imagen = null;
        }
      }
      this.imagenTocada = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}
@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 10px;
  }
}
</style>
